/* eslint-disable @wordpress/dependency-group */
import $ from 'jquery';

import './inc/responsive-menus';
import animateBlocks from './inc/animate';
import onScroll from './inc/on-scroll';

window.addEventListener('DOMContentLoaded', animateBlocks);
window.addEventListener('DOMContentLoaded', function () {
	onScroll.init();
});

window.addEventListener('DOMContentLoaded', function () {
	document.body.classList.add('js-loaded');
});

// window.addEventListener('DOMContentLoaded', function () {
// 	setTimeout(function () {
// 		var theLink = document.querySelector(
// 			'body #eapps-instagram-feed-1 > a'
// 		);
// 		theLink.removeAttribute('style');
// 	}, 1000);
// });

const animateBlocks = () => {
	const awards = document.querySelectorAll('.awards .wp-block-heading');
	const awardsColumns = document.querySelectorAll(
		'.awards .wp-block-columns'
	);
	const howJoin = document.querySelectorAll('.how-join .wp-block-heading');
	const listItems = document.querySelectorAll('.how-join li');
	const notes = document.querySelectorAll('.how-join-note');
	// const sectionGalleries = document.querySelectorAll('.section-gallery');
	const usersImages = document.querySelectorAll('.user-images');
	const observer = new IntersectionObserver(handleIntersection, {
		threshold: 0.1,
	});

	function handleIntersection(entries, observer) {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				const el = entry.target;
				el.classList.add('in-view');
				observer.unobserve(el);
				// console.log('entry.intersectionRatio', entry.intersectionRatio);
				// if (entry.intersectionRatio >= 0.3) {

				// }
			}
		});
	}

	for (const award of awards) {
		observer.observe(award);
	}

	for (const awardsColumn of awardsColumns) {
		observer.observe(awardsColumn);
	}

	for (const join of howJoin) {
		observer.observe(join);
	}

	for (const listItem of listItems) {
		observer.observe(listItem);
	}
	for (const note of notes) {
		observer.observe(note);
	}
	// for (const sectionGallery of sectionGalleries) {
	// 	observer.observe(sectionGallery);
	// }
	for (const userImages of usersImages) {
		observer.observe(userImages);
	}
};

export default animateBlocks;

const onScroll = ((window, $) => {
	const init = () => {
		// const siteHeader = $('.site-header');
		const siteInner = $('.site-inner');
		const distanceFromTop = siteInner.offset().top;
		// eslint-disable-next-line @wordpress/no-unused-vars-before-return,no-unused-vars
		// const siteHeaderHeight = siteHeader.outerHeight(true);
		$(window).on('scroll', function () {
			// console.log('scrolllll');
			// eslint-disable-next-line @wordpress/no-unused-vars-before-return,no-unused-vars
			// const sticky = $('header.site-header'),
			const scroll = $(window).scrollTop();
			if (scroll >= distanceFromTop + 47) {
				$('body').addClass('is-sticky');
			} else {
				$('body').removeClass('is-sticky');
			}
		});
	};

	return {
		init,
	};
})(window, jQuery);

export default onScroll;
